#responsive-navbar-nav {
    justify-content: center;
}

.navbar {
    background-color: white !important;
}

@media only screen and (max-width: 768px) {
    #desktop-navbar-brand {
        display: none;
    }
    
    #mobile-navbar-brand {
        display: block;
    }
}

@media only screen and (min-width: 768px) {
    #desktop-navbar-brand {
        display: block;
    }
    
    #mobile-navbar-brand {
        display: none;
    }
}

