@media only screen and (max-width: 1000px) {
    #parent-div-aboutus{
        flex-direction: column;
    }
}

.col-our-team{
    flex-grow: 0 !important;
}

.row-our-team{
    justify-content: center;
}