.RSPBprogressBar .RSPBstep{
    position: initial !important;
}
.RSPBprogressBar {
    height: 2px !important;
    width: 100%;
    background-color: black;
}

#step_button:hover {
    cursor: pointer;
}