body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@font-face {
  font-family: "FuturaMediumBold";
  src: local("FuturaMediumBold"), url("./assets/FuturaMediumBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "PoppinsMedium";
  src : local("PoppinsMedium"), url("./assets/PoppinsMedium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "PoppinsLight";
  src : local("PoppinsLight"), url("./assets/PoppinsLight.ttf") format("truetype");
  font-weight: normal;
}

@font-face{
  font-family: "FuturaCondensedMedium";
  src: local("FuturaCondensedMedium"), url("./assets/FuturaCondensedMedium.otf") format("truetype");
  font-weight:normal;
}

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

.btn-primary {
  background-color:#CE9B64 !important;
  border: solid 1px #CE9B64 !important;
}