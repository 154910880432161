.carousel-caption {
    position: absolute;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    right: 0 !important;
    bottom: inherit;
}

.carousel-item {
    transition: all 1s !important;
}

.carousel-inner .carousel-item {
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    position: absolute;
}

.carousel-inner .carousel-item:first-of-type {
    position: relative;
}
 
.carousel-inner .active {
    opacity: 1;
}